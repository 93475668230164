const releases = [
  {
    diva: 23,
    type: "album",
    name: "Ela Caiu",
    artist: "Marquise",
    year: 2025,
    cover: process.env.PUBLIC_URL + "/assets/cover_ela_caiu.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_ela_caiu.jpeg",
    instagram: "https://www.instagram.com/m.ar.qui.se/",
    facebook: "https://www.facebook.com/",
    email: "somososmarquise@gmail.com",
    youtube: "https://www.youtube.com/@Marquise-yg3hk",
    spotify:
      "https://open.spotify.com/album/19tfYGWNLwJBk7XmVSIhLS?si=YGYYDM5HSr-s-lJW1GA_0Q",
    bandcampLink: "https://salivadiva.bandcamp.com/album/ela-caiu",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=4031674762/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      'Há artistas que carregam o ceptro da intemporalidade, que não deixam apagar a chama olímpica, que resistem à sedução do contemporâneo, esse falso amigo com que todas as obras datadas um dia se envolveram. Marquise soa a Banda, e "Ela Caiu" prova que essa forma de expressão (e de ser) colectiva faz tanto sentido agora como quando surgiu. Marquise não precisou de reinventar a roda para honrar a arte de fazer boas canções - bastou-lhe equilibrar o passado e o presente com minúcia de relojoeiro, dedilhar a sensibilidade pop dos 80s sobre o pára-arranca dos 90s, e pincelar o desconstrutivismo sónico deste século com poesia abstrata. Apesar de construída com materiais acessíveis, esta Marquise apresenta arrojo arquitectónico na forma como deixa passar a luz natural, que tanto incide nos ombros de Alberto Caeiro como no baixo escavacado aos pés de Krist Novoselic, e ainda pela porta falsa para a cave de ocasionais festas góticas. Ela caiu mas levantou-se, alicerçada em canções e refrões orelhudos, rock duro e doce como pedra e bolo mármore, guiada por uma voz feminina que tanto derrete mel sobre tarolas marteladas, como debita raiva sobre guitarras melosas. Esta Marquise apanha sol, areja a casa do rock português, limpa as teias mas deixa as aranhas, e de repente, já não cheira a mofo. "Ela Caiu" é o primeiro álbum de Marquise, sucedendo ao EP homónimo que apresentou a banda portuense em 2023, e é editado pela Saliva Diva. Está disponível em vinil, CD, e nas plataformas digitais.',
  },
  {
    diva: 22,
    type: "album",
    name: "CHAT GR(E)P",
    artist: "Chat GRP",
    year: 2024,
    cover: process.env.PUBLIC_URL + "/assets/cover_chat_grep.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_chat_grep.jpg",
    instagram: "https://www.instagram.com/_chat.grp/",
    facebook: "https://www.facebook.com/",
    email: "",
    youtube: "https://www.youtube.com/@ChatGRP",
    spotify:
      "https://open.spotify.com/album/0XrMjee3xPCfFSFjlcyh0S?si=8yVteuoARFOXKoDZCUDKrQ",
    bandcampLink: "https://salivadiva.bandcamp.com/album/chat-gr-e-p",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=565106391/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      "28 de Março, 2024 A.D., planeta Terra, Portugal, Fafe, Hollywood, Café Avenida, 21:30h. Ninguém estava à espera do que estava prestes a acontecer. Como uma enchente repentina, todos foram abalados com o que assistiram, a estreia em palco, o nascimento de CHAT GRP. Assim, a banda se apresentou. Quatro gatos pingados, gatunos, marmanjos, reguilas, alcoólatras, filisteus, diplomatas analfabetos, enginheiros da decadência e acima de tudo, amigos. Todas estas qualidades caóticas, que os mantêm juntos, previamente mencionadas refletem o tipo de som que estes seres geram. É um som que como eles caótico, cheio de sátira e provocação, como um familiar que gosta de sacanear num jantar de família. É um som que não podem definir ao certo, mas tem sempre, como o medronho, um sabor consistente, às vezes mais áspero, às vezes mais suave, mas quando é medronho, é medronho. Quando é pós punk, é pós punk. Quando é CHAT GRP, é medronhopunk. CHAT GRP é composto pelos catalisadores Francisco Almeida (bateria),Francisco Cabrita (voz), Pedro Campos (guitarra, voz), Zé Rego (baixo, voz). CHAT GRP são. Se não fôr para jardar, eles não querem.",
  },
  {
    diva: 21,
    type: "album",
    name: "Acrobatics of the Flesh",
    artist: "Sensor",
    year: 2024,
    cover: process.env.PUBLIC_URL + "/assets/cover_acrobatics_of_the_flesh.jpg",
    banner:
      process.env.PUBLIC_URL + "/assets/banner_acrobatics_of_the_flesh.png",
    instagram: "https://www.instagram.com/ramrife/",
    facebook: "https://www.facebook.com/",
    email: "",
    youtube: "https://www.youtube.com/",
    spotify: "",
    bandcampLink:
      "https://salivadiva.bandcamp.com/album/acrobatics-of-the-flesh",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=99174297/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      "Sensor é um colectivo de improvisação sonora a partir dos detritos fragmentários de algumas das expressões musicais marginais da segunda metade do século (deep roots jazz, metal industrial e alt-rock), cristalizado num diálogo precário e instável entre vozes instrumentais de guitarra, saxofone, bateria e electrónicas. A música de Sensor é o resultado de um processo essencialmente performático suportado na intenção de criação de um fluxo transmissivo de sons e imagens, com o objectivo último, e sempre altamente imprevisível, da descoberta de entendimentos e emoções consensuais dentro de paisagens-limite.",
  },
  {
    diva: 20,
    type: "album",
    name: "Drumhard Sessions Vol. 1",
    artist: "Splitterzelle",
    year: 2024,
    cover: process.env.PUBLIC_URL + "/assets/cover_drumhard_sessions_vol_1.jpg",
    banner:
      process.env.PUBLIC_URL + "/assets/banner_drumhard_sessions_vol_1.jpeg",
    instagram: "https://www.instagram.com/splitterzelle/",
    facebook: "https://www.facebook.com/people/Splitterzelle/61558748120468/",
    email: "splitterzelle.band@gmail.com",
    youtube: "https://www.youtube.com/",
    spotify: "",
    bandcampLink:
      "https://salivadiva.bandcamp.com/album/drumhard-sessions-vol-1",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=2576576129/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      "Uma banda luso-germânica com motores a fuzz de germânio aterra em parte desconhecida. O terreno é árido, mas ao longe espreita uma cidade imponente entrecortada pelo branco da montanhas. Entre a neve e o deserto, o eco e a reverbaração filtrados pelo germânio fazem avançar a batida, criando um vórtice hipnótico. Splitterzelle cheira ao fim do mundo e seu recomeço, a civilizações ancestrais de telemóvel na uma mão e um osso de pau na outra. É rock psicadélico livre, que tanto repete como muda, qual MacGyver com um clipe, uma pastilha e um pedal fuzz de germânio luso-germânico, com motores em parte desconhecida.",
  },
  {
    diva: 19,
    type: "album",
    name: "Abaixo das raízes deste jardim",
    artist: "Ξvдyд",
    year: 2024,
    cover:
      process.env.PUBLIC_URL +
      "/assets/cover_abaixo_das_raizes_deste_jardim.jpg",
    banner:
      process.env.PUBLIC_URL +
      "/assets/banner_abaixo_das_raizes_deste_jardim.jpeg",
    instagram: "https://www.instagram.com/evaya___/",
    facebook: "https://www.facebook.com/evayamusic",
    email: "agente@haushaus.pt",
    youtube: "https://www.youtube.com/@iforgotiwasme/",
    spotify:
      "https://open.spotify.com/album/372yRxMEHBzkicg3jIVaq9?si=g_bZuaDfQfyXSlwuahT44Q",
    bandcampLink:
      "https://salivadiva.bandcamp.com/album/abaixo-das-ra-zes-deste-jardim",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=2923691333/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      'Abaixo das raízes deste jardim esperam-nos criaturas, anjos do interior da terra, demónios quentes como o sussurrar cantado de Evaya. O glaciar derrete a nossos pés com uma linha de baixo, libertando espíritos congelados de espécies extintas, alados antepassados com batidas nos ossos, fantasmas dissidentes, plantas a germinar na tundra."Abaixo Das Raízes Deste Jardim" é o primeiro álbum de Evaya, que confirma as expectativas criadas pelo EP de estreia "INTENÇÃO" (2020). Uma viagem electrónica e espiritual, uma oferenda de synth pop ao sol, suave como seda, esguia como minhoca na terra, enguia no mar ou asteróide no espaço.',
  },
  {
    diva: 18,
    type: "album",
    name: "Denso",
    artist: "Galgo",
    year: 2024,
    cover: process.env.PUBLIC_URL + "/assets/cover_denso.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_vapor.jpeg",
    instagram: "https://www.instagram.com/galgogalgogalgo/",
    facebook: "https://www.facebook.com/galgogalgogalgo/?locale=pt_PT",
    email: "galgo.cinco@gmail.com",
    youtube: "https://www.youtube.com/@GalgoOficial/videos",
    spotify:
      "https://open.spotify.com/track/3401iYDeyK2UXWU8WfFKDw?si=83638d02d6a94214",
    bandcampLink: "https://salivadiva.bandcamp.com/album/denso",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=2994996938/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      'Prime o botão G, seleciona o modo "Denso" e escolhe se queres lutar pelo passado ou pelo presente. De um lado, faraós despem ligaduras e agitam os esqueleto afiados, do outro, adolescentes desembaínham anéis de luz e espadas de selfies. A pista de dança côncava transforma-se em ringue de batalha. Aceitam-se apostas. Há algo de ancestral e combativo na modernidade dos Galgo, entre os ocasionais ritmos matemáticos marcados no ábaco, sintetizadores com sabores analógicos e guitarras de cabelo à tigela ou chamirro. "Denso" é a banda sonora perfeita para uma distopia, seja a imaginação em que vivemos ou a realidade que cada um de nós imagina.',
  },
  {
    diva: 17,
    type: "album",
    name: "Guardilha Espanca Tato",
    artist: "MONCHMONCH",
    year: 2023,
    cover: process.env.PUBLIC_URL + "/assets/cover_guardilha_espanca_tato.jpg",
    banner:
      process.env.PUBLIC_URL + "/assets/banner_guardilha_espanca_tato.jpeg",
    instagram: "https://www.instagram.com/monch_monch_/",
    facebook: "",
    email: "",
    youtube: "https://www.youtube.com/@monchmonch6272",
    spotify:
      "https://open.spotify.com/album/70PvuIXB9g3ytlMMc0UwW9?si=NZKcDbPtRHK9AqwaapT3OA",
    bandcampLink:
      "https://salivadiva.bandcamp.com/album/guardilha-espanca-tato",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=2931154559/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      'Formado na hipotética Escola Superior de Psicadelismo de São Paulo, MONCHMONCH atravessa o Atlântico numa jangada equilibrada por dissonância e melodia, onde cabe ainda a garagem e um mastro onde sopra o megafone do absurdo. Baixo e bateria remam em sincronia, enquanto guitarras e teclados encantam peixes de grande porte e outros cetáceos ultramarinos. Da gestação destes cruzamentos de espécies surge um rock enérgico que não recusa momentos taciturnos, ora velejando à luz de velas no ócio do oceano, ora às cavalitas de roedores em ilhéus de plástico. Retro sem nostalgia, como pó de talco nos punhos de Muhammad Ali. Aqui, agora, de frente para as margens costeiras da Europa, eis "Guardilha Espanca Tato", lançado recentemente pela Seloki Records no Brasil, e agora em Portugal pela Saliva Diva.',
  },
  {
    diva: 16,
    type: "album",
    name: "Músicas de dança para pessoas tristes",
    artist: "Luís Contrário",
    year: 2023,
    cover:
      process.env.PUBLIC_URL +
      "/assets/cover_musicas_de_danca_para_pessoas_tristes.jpg",
    banner:
      process.env.PUBLIC_URL +
      "/assets/banner_musicas_de_danca_para_pessoas_tristes.jpeg",
    instagram: "https://www.instagram.com/luiscontrario/",
    facebook: "",
    email: "",
    youtube: "https://www.youtube.com/@LuisContrario",
    spotify:
      "https://open.spotify.com/album/1YmH3SCgswckctSKp0iAda?si=urGm5GkaTNO_Xx5XL3401g",
    bandcampLink:
      "https://salivadiva.bandcamp.com/album/m-sicas-de-dan-a-para-pessoas-tristes",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=3762494761/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      'Luís Contrário fecha-se no quarto, liga o material, e de uma batida nasce "Músicas de dança para pessoas tristes". O baixo, ondulado e crocante como um pacote de batata frita acabado de abrir, dança e chora ao mesmo tempo, qual carpideira na discoteca depois do funeral. Os temas são curtos, directos ao assunto, viciantes como cerveja e uma latinha de cajus numa tarde de sol no Outono. "Músicas de dança para pessoas tristes" é uma ode à auto-comiseração que não se leva demasiado a sério, um hino a ser do contra, uma procura de luz entre a névoa. Dança, rapaz, dança!',
  },
  {
    diva: 15,
    type: "album",
    name: "Marquise",
    artist: "Marquise",
    year: 2023,
    cover: process.env.PUBLIC_URL + "/assets/cover_marquise.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_marquise.jpeg",
    instagram: "https://www.instagram.com/m.ar.qui.se/",
    facebook: "",
    email: "somososmarquise@gmail.com",
    youtube: "https://www.youtube.com/channel/UCrmAbGsZKBAmKPWsYcQ3TDw",
    spotify:
      "https://open.spotify.com/album/2ZlSaVEe3RVq8DszLuNzFX?si=P9cnlV0AS9aENeHwwUv8OQ",
    bandcampLink: "https://salivadiva.bandcamp.com/album/marquise",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=775716849/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      "A Marquise surge no país das morgadinhas, da tradição dos pavimentos melódicos dos anos 90, das baixelas marteladas e pratos ondulados pelo vento. Tudo começa por M nos canaviais do rock que Júlio Dinis sonhou, onde meninas bonitas usam pedais de distorção e criticam ideais liberais - Marquise é Mafalda, Matias, e Miguel vezes dois, como quem se olha ao espelho-elho-elho. Juntos fazem Música de quem kurte o Cobain, a piscar o olho aos génios suicidas de eras feitas à mão, com uma voz feminina capaz de os trazer novamente à vida. Em Seattle chovia o ano todo e os músicos fechavam-se na garagem a tocar. A Marquise queria ser uma varanda no Porto, mas taparam-lhe o ar livre. Agora, munida de garganta, baquetas e palhetas, ataca ferozmente as paredes para se dar a conhecer ao mundo. As canções estão a bater com força, abram-lhe uma janela!",
  },
  {
    diva: 14,
    type: "album",
    name: "Megafauna",
    artist: "Daniel Catarino",
    year: 2023,
    cover: process.env.PUBLIC_URL + "/assets/cover_megafauna.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_megafauna.jpeg",
    instagram: "https://www.instagram.com/catarinod/",
    facebook: "https://www.facebook.com/catarinodaniel",
    email: "",
    youtube: "https://www.youtube.com/@DanielCatarino/featured",
    spotify:
      "https://open.spotify.com/album/2XUmaaaXeZDmM8bPw44DXO?si=BcgQyc67S86BapFaNxK4Ow",
    bandcampLink: "https://salivadiva.bandcamp.com/album/megafauna",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=2073893785/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      '“Megafauna” é o novo disco de Daniel Catarino, o primeiro da Trilogia Bioma, em que se abordam dúvidas existenciais sem propor qualquer resposta, e se questiona quem se acha no direito de ter certezas. É um disco de cantautor, mas com os amplificadores bem altos. A fauna proposta no título do álbum surge representada logo no primeiro tema, "Manequim", e propaga-se por "Fado do Caixão" e "Olho do Tubarão", que retratam os humanos pela forma como tratam os animais e os seus habitats, entre porcos a que só é reconhecido valor pelo sabor dos seus cadáveres, repastos de peixes em extinção, cães e gatos maltratados, e um tubarão que chora plástico.Se "Fodidos" e "Até o Mais Honesto é Guloso" abordam a política social na era das opiniões debitadas em instrumentos de plástico, também há entradas a pés juntos no osso da precariedade artística em "Sonhos Sem Objectivos" e "Berço de Ouro", que soltam lágrimas de raiva e uivos de ironia por não vivermos o mundo que sonhámos. Embora os temas sejam largamente universais, "Teias de Aranha" fecha o disco com desabafos pessoais, narrando hipóteses perdidas, arrependimentos mal resolvidos e o amor enquanto força (des)motivadora. Musicalmente, é rock. São canções rock despretensiosas, interpretadas maioritariamente no formato power trio que apresenta ao vivo, com momentos de psicadelia não conformada. É possível que não seja disco de deixar a rodar enquanto se escreve uma tese de mestrado ou se lava a loiça. Há malhas de guitarra que entram para furar os ouvidos, as letras tanto dão coices como carícias, o baixo dança de crista em riste, a bateria evoca pentagramas. Se os tipos do grunge não tivessem morrido, talvez soassem assim agora. É rock, com a honestidade que se lhe deve quando se tenta observar a vida de perto com olhos de satélite. Só dúvidas, zero respostas.',
  },
  {
    diva: 13,
    type: "album",
    name: "floating lines",
    artist: "Nile Valley",
    year: 2023,
    cover: process.env.PUBLIC_URL + "/assets/cover_floating_lines.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_floating_lines.jpeg",
    instagram: "https://www.instagram.com/nilevalleyband/",
    facebook: "https://www.facebook.com/nilevalleyband/",
    email: "nilevalleymusicpt@gmail.com",
    youtube: "https://www.youtube.com/channel/UCF42RdykH5h1w5sTEEJL8EA",
    spotify:
      "https://open.spotify.com/artist/2NzXt0c7Yb8uiasuBQ767v?si=SW6ez_lFSS64OkJfzWbB4Q",
    bandcampLink: "https://salivadiva.bandcamp.com/album/floating-lines",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=1213286721/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      'As linhas flutuantes traçadas por Nile Valley invocam o prazer de pequenos delitos prazerosos a meio de um dia de trabalho, enquanto o disco repete até se transformar num mantra. Depois, numa escuta mais atenta fora do expediente, a sensualidade dos pormenores melódicos e de produção faz arrepiar a pele e subir a temperatura nas veias. Agita-se o R&B na cadeira, serve-se um trip hop de vinho, atenuam-se as luzes electrónicas, e o ambiente está criado. O que acontece depois é só parcialmente responsabilidade da banda portuense. É que "floating lines" tanto pode ser banda sonora para uma noite de loucura como de introspecção.',
  },
  {
    diva: 12,
    type: "album",
    name: "Dirty Tracks for Clubbing",
    artist: "MДQUIИД.",
    year: 2023,
    cover:
      process.env.PUBLIC_URL + "/assets/cover_dirty_tracks_for_clubbing.jpg",
    banner:
      process.env.PUBLIC_URL + "/assets/banner_dirty_tracks_for_clubbing.jpg",
    instagram: "https://www.instagram.com/maquiiinaria/",
    facebook: "",
    email: "",
    youtube: "https://www.youtube.com/watch?v=hZ5gsgacLFU",
    spotify:
      "https://open.spotify.com/album/7vCLEizWTP0lqwuG3GHihu?si=Ebb_hC3US0OEKpTNgX03ow",
    bandcampLink:
      "https://salivadiva.bandcamp.com/album/dirty-tracks-for-clubbing-2",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=219707536/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      "A MДQUIИД vem de Lisboa munida de pedais de efeitos que se querem fazer notar e de uma constância rítmica que roça a hipnose. Não é fácil catalogar a música da banda mas diríamos que estão perto de um rock sujo com psicadelismo em tons negros e piscares de olhos pouco tímidos à força industrial da dança. Ao entrarmos de cabeça em “Dirty Tracks For Clubbing” (álbum de estreia do trio), percebemos facilmente porque já estabeleceram uma reputação pela energia dos seus concertos. A linha de baixo é o motor automático da explosão, a bateria assume o volante e segue prego a fundo, as texturas ruidosas da guitarra provocam atrito constante no asfalto e há um GPS na bagageira de onde ouvimos uma voz a indicar o caminho em gritos distorcidos. MДQUIИД é a companhia perfeita num bunker clandestino. Tem aromas de cave roqueira onde chove cerveja e do suor das paredes de betão numa rave sem hora para acabar. Cheira a perigo.",
  },
  {
    diva: 11,
    type: "album",
    name: "Lama Pela Anca",
    artist: "Palankalama",
    year: 2022,
    cover: process.env.PUBLIC_URL + "/assets/cover_lama_pela_anca.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_lama_pela_anca.jpg",
    instagram: "https://www.instagram.com/palankalama/",
    facebook: "https://pt-pt.facebook.com/palankalama/",
    email: "",
    youtube: "https://www.youtube.com/channel/UCh3Vq9TTXkE7fsfwFxhQmjQ",
    spotify:
      "https://open.spotify.com/artist/2qadak2dXVVLHDpyfAuCVV?si=rLYKuS26SmSEvmTZC0tLCA",
    bandcampLink: "https://salivadiva.bandcamp.com/album/lama-pela-anca",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=3433238127/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      'Os Palankalama trazem uma mensagem empática que atravessa ritmos e tradições, como se as fronteiras fossem invenções bárbaras dos caceteiros de antanho. A secção rítmica abre caminho, e o cavaquinho pega na mão da guitarra jazzística para que não se perca na estrada azul entre Porto e Nova Orleães. Neste safari musical, complexo mas descomplexado, atravessamos a selva urbana num carro antigo mas vistoso, sobre o trilho de linhas de baixo dançantes que apanham e largam passageiros nas paragens. Caminhamos até ao outro lado do oceano, mas calçados pelas raízes deste solo ocidental no velho continente. "Lama Pela Anca" é o terceiro disco dos Palankalama, banda nascida no Porto em 2014 e adoptada por Afonso Passos, Anibal Beirão, Pedro João e Ricardo Nogueira, e é a 11ª edição da Saliva Diva.',
  },
  {
    diva: 10,
    type: "album",
    name: "Fountain of Shingle",
    artist: "Melquiades",
    year: 2022,
    cover: process.env.PUBLIC_URL + "/assets/cover_foutain_of_shingle.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_foutain_of_shingle.jpg",
    instagram: "https://www.instagram.com/melquiadesband/",
    facebook: "https://www.facebook.com/melquiadesband/",
    email: "",
    youtube: "https://www.youtube.com/channel/UCE1E5-dRL69_oWTttksu91A/",
    spotify:
      "https://open.spotify.com/album/1uSrypHidmqFD4xWh20Lqn?si=OGrl1-2bSRiWMnCTVZY01Q",
    bandcampLink: "https://salivadiva.bandcamp.com/album/fountain-of-shingle",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=933304813/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      "Estamos no cosmos, mas em galáxias diferentes. A distância a que observamos as estrelas não nos permite distingui-las a olho nu. Na Terra, estamos em 314 D.C. e o papa berbere Melquíades troca a sua convicção por um sintetizador a pilhas. Junta-se aos imperadores Constantino, Licínio e Magêncio, e forma uma banda na tentativa de caçar Dahaka, a grande cobra persa de três cabeças e corpo de guitarra. Pelo caminho encontram Iamenjá, a orixá da fertilidade, que sugere o rock e o psicadelismo como as melhores armas para a sua caçada, e junto ao rio ensina-lhes ritmos compostos para afastar inimigos. A batalha final ocorre contra Lavos, no concelho da Figueira da Foz, onde Melquíades recebe finalmente a carta de foral “Fountain of Shingle”. Desta fonte nasce uma entidade progressiva e arrojada, um colectivo que se benze na fonte de cascalho para que dela escorra groove em vez de água. “Fountain of Shingle” é o primeiro longa-duração dos lisboetas Melquíades, com lançamento a 14 de Abril de 2022 pela editora portuense Saliva Diva.",
  },
  {
    diva: 9,
    type: "album",
    name: "Dandruff",
    artist: "Fugly",
    year: 2022,
    cover: process.env.PUBLIC_URL + "/assets/cover_dandruff.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_dandruff.jpg",
    instagram: "https://www.instagram.com/fuglyfuglyfugly/",
    facebook: "https://www.facebook.com/fuglyfuglyfugly/",
    email: "",
    youtube: "https://www.youtube.com/channel/UCiA17QHA29eFaO0rg5p82Fg/",
    spotify:
      "https://open.spotify.com/album/7K8tF4dckNx5SWkOGm6CoU?si=W7TTPylJQc2uCGBHcQz5qg",
    bandcampLink: "https://salivadiva.bandcamp.com/album/dandruff",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=2920279695/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      "Caspa com gáspea, ou como os Fugly fazem girar a roda sem precisarem de a reinventar. Em “Dandruff”, a banda percorre os anos 90 sem dar no cavalo, a propagar movida em vez de depressão, a refletir sobre questões da sua classe e da sua geração, mas sempre com ritmo, pujança, poder, gáspea. Não é música de ficar a olhar, embora impressione a resiliência com que as guitarras mantêm a simplicidade para que a secção rítmica se destaque. Todos os músicos saberão o quão difícil é a disciplina da repetição, e nisso, os Fugly são exemplares. São 36 minutos de trotineta com fones no ouvido, em que o presente atravessa a nébula da nostalgia sem medo do breu, confiante de que sairá incólume no céu aberto da cidade. Aqui, as estrelas não são vedetas, brilham porque outros olhos as observam, dançam com quem dança, abanam a cabeça porque há dores no pescoço que são terapêuticas. “Dandruff” está disponível em vinil, CD e formatos digitais a partir 18 de Março de 2022, e é o nono lançamento da editora portuense Saliva Diva, com apoio da Fundação GDA.",
  },
  {
    diva: 8,
    type: "album",
    name: "Suicídio Comercial",
    artist: "Baleia Baleia Baleia",
    year: 2022,
    cover: process.env.PUBLIC_URL + "/assets/cover_suicidio_comercial.png",
    banner: process.env.PUBLIC_URL + "/assets/banner_baleia.jpg",
    instagram: "https://www.instagram.com/baleia_baleia_baleia/",
    facebook: "https://www.facebook.com/Baleiax3/",
    email: "",
    youtube: "https://www.youtube.com/channel/UCCloJP65wVpQ-E8cek8g5KA",
    spotify:
      "https://open.spotify.com/album/52pxz3LCBy2ti6AZuseqbr?si=YmkmZKswTQKF9Z_YPUIz0A",
    bandcampLink: "https://salivadiva.bandcamp.com/album/suic-dio-comercial",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=2469031789/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      'Dupla formada por Manuel Molarinho (baixo e voz) e Ricardo Cabral (bateria e voz), formada emfinais de 2015 no Porto a partir de jams informais. Lançaram em 2-2-2022, "SUICÍDIO COMERCIAL", depois de um primeiro EP pirata (2017) e do disco de estreia homónimo (2018) que lhes valeu presenças constantes nos palcos de todo o país, incluindo festivais como o Bons Sons, Circuito Supernova ou o ZigurFest, bem como nas listas de melhores discos e concertos do ano. Pode considerar-se "SUICÍDIO COMERCIAL" um álbum de continuidade em relação ao anterior mas com uma toada mais existencialista e uma energia mais aproximada à que a banda demonstra em palco. Os concertos são o habitat natural do duo, sendo que neste disco tentaram respeitar mais a energia própria das músicas e menos o contraste de forças entre o disco e o concerto, sem descurar a liberdade imensa que existe no estúdio. Se falar de assuntos sérios com leveza, inspirados na inesgotável fonte de ideias absurdas que é a realidade contemporânea, é o mote do duo, o espírito DIY é o modus operandi. Os vídeos foram totalmente idealizados, realizados e produzidos pela banda. O álbum é também produzido pelos BALEIA BALEIA BALEIA, no estúdio Quarto Escuro, em colaboração com Tiago Ralha que co-misturou e masterizou. O artwork ficou a cargo de Sara Quintanilha.',
  },
  {
    diva: 7,
    type: "album",
    name: "O Ecoar d'uma Sirene",
    artist: "Tiago e os Tintos",
    year: 2021,
    cover: process.env.PUBLIC_URL + "/assets/cover_o_ecoar_duma_sirene.png",
    banner: process.env.PUBLIC_URL + "/assets/banner_o_ecoar_duma_sirene.jpg",
    instagram: "https://www.instagram.com/tiagoetintos/",
    facebook: "https://www.facebook.com/tiagoetintos/",
    email: "",
    youtube: "https://www.youtube.com/watch?v=JVvo6rejuM4",
    spotify: "",
    bandcampLink: "https://salivadiva.bandcamp.com/album/o-ecoar-duma-sirene",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=3808048743/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      'O cenário é escuro, o fumo flui entre as guitarras como se os anos 90 aterrassem numa consciência do século XXI. A poesia dilui-se no guardanapo queimado pelo bagaço e a voz surge entre caveiras e jardins floridos. A fita VHS tem resolução 4K, a camisa de flanela tem padrões havaianos e um monitor de actividade no braço conta os passos entre o Porto de 2021 e a Seattle de 1993. "O Ecoar d\'uma Sirene", disco que apresenta Tiago e os Tintos ao mundo, tem carne revivalista mas usa fato moderno, de fibra orgânica. É um relato crú e autobiográfico do quotidiano de Tiago Faria, que acompanhado pelos membros de Terebentina e Gota, debita melodias pop sobre guitarras no limiar da dissonância, em paz com o passado e com o coração no presente. Gravado no Porto por Ricardo Cabral (Baleia Baleia Baleia), produzido por João Sarnadas (Coelho Radioactivo) e masterizado por Pedro Ledo (The Miami Flu), floresce para o mundo a 11 de novembro de 2021, dia de São Martinho, numa colaboração entre as editoras Saliva Diva e Berga Malhas. As canções são quentes e boas, sirva-se água-pé e jeropiga.',
  },
  {
    diva: 6,
    type: "album",
    name: "Reunion Day",
    artist: "The Miami Flu",
    year: 2021,
    cover: process.env.PUBLIC_URL + "/assets/cover_reunion_day.png",
    banner: process.env.PUBLIC_URL + "/assets/banner_reunion_day.jpg",
    instagram: "https://www.instagram.com/themiamiflu/",
    facebook: "https://pt-pt.facebook.com/themiamiflu/",
    email: "",
    youtube: "",
    spotify: "https://open.spotify.com/embed/album/4TLjKlHe3HmJOCdlDpRtB3",
    bandcampLink: "https://salivadiva.bandcamp.com/album/reunion-day",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=589222973/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      "Os The Miami Flu nascem inspirados pelo psicadelismo dos anos 60 e 70 com o pano de fundo dos videogames com o primeiro disco ‘Too Much Flu Will Kill You’, editado em 2016. Neste disco viajamos num registo lo-fi entre temas que retratam universos atípicos como um amor platónico por um comprimido, universos e galáxias microscópicas. E tal como qualquer vírus também os The Miami Flu sofreram mutações. Em 2021 lançam novo disco 'Reunion Day', também gravado pela própria banda mas produzido pelo Zé Nando Pimenta dos Estúdios Meifumado. Com uma sonoridade hi-fi, hiper realista e polida mantém-se fiéis ao rock psicadélico mas olham-no de uma forma diferente porque tudo está diferente, em constante evolução, irrepetível como o passado e contínuo como o futuro.",
  },
  {
    diva: 5,
    type: "album",
    name: "Isolamento Voluntário?",
    artist: "Daniel Catarino",
    year: 2021,
    cover: process.env.PUBLIC_URL + "/assets/cover_isolamento_voluntario.jpeg",
    banner: process.env.PUBLIC_URL + "/assets/banner_catarino.jpg",
    instagram: "https://www.instagram.com/catarinod/",
    facebook: "https://www.facebook.com/catarinodaniel",
    email: "",
    youtube: "",
    spotify:
      "https://open.spotify.com/album/4Ifh3C7lf5dHgKDsnJhYBg?si=t_T59x9VTUGTpVeBUnOakA",
    bandcampLink: "https://salivadiva.bandcamp.com/album/isolamento-volunt-rio",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=3578799032/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      '"Isolamento Voluntário?" é o novo disco de Daniel Catarino, mas não estava pensado para o ser. As canções foram surgindo quase como diário cronológico do autor alentejano durante a pandemia, com o intuito de escamotear os momentos em que cada uma delas sentiu necessidade de nascer." A pandemia fez-nos oscilar entre a sanidade e a esperança, da tragédia constante à diminuição dos níveis de poluição, dos excessos de autoritarismo aos gestos mais humanos. Neste disco, que é uma crónica poética mas fidedigna da confusão instalada, estas contradições são abordadas com a empatia de quem compreende o mundo à sua volta e tem o engenho de o condensar em canções desafiantes. É música do agora e para já." - Jorge Ferreira',
  },
  {
    diva: 4,
    type: "album",
    name: "Centelha",
    artist: "Bardino",
    year: 2020,
    cover: process.env.PUBLIC_URL + "/assets/cover_centelha.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_bardino.jpg",
    instagram: "https://www.instagram.com/osbardino/",
    facebook: "https://www.facebook.com/bardino.band/",
    email: "brigidasrramos@gmail.com",
    youtube: "https://www.youtube.com/channel/UCKZ2tDUNIYGfuHWtdjMCFGA",
    spotify:
      "https://open.spotify.com/artist/2r22BWyVwsYIe2KzlqxgDA?si=nQ5Mm4L-TFaiaDCCdKJ6GQ",
    bandcampLink: "https://salivadiva.bandcamp.com/album/centelha",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=3367591724/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    review:
      "Com uma nova formação em trio, os Bardino apresentam em “Centelha” um registo menos lineare menos ligado ao rock que os anteriores. A banda do Porto desenvolve agora uma narrativa sónicae textural mais consistente e conceptual, com aproximações à electrónica. Se fecharmos os olhos nas primeiras notas, podemos imaginar o sol transformado em caleidoscópio numa recta estendida pelas planícies alentejanas, com 40 graus a baterem-nos na testa. À medida que o disco avança, somos transportados até ao fluxo calmo de um rio, onde um cacho de uvas se banha em delírios tropicais. Do seu néctar brota o vinho que tão bem acompanha a música dos Bardino - suave, fresca, repleta de cores e de vida, com aroma frutado e, ao contrário do líquido de Baco, excelente companheira de viagem. “Centelha”, gravado no início de 2020, é um álbum que não precisa de mapa nem de GPS. Orienta-se muito bem sozinho, seja a grande velocidade pelas autoestradas, contornando as inevitáveis rotundas das estradas nacionais, e por vezes sob a trepidação dos imprevisíveis caminhos de cabras. Guiados pelos teclados de Rui Martins, com Nuno Fulgêncio a alimentar a bateria e Diogo Silva a fazer vibrar o motor com o seu baixo, os Bardino soam a uma banda experimentada que não receia a experimentação. Do Porto para o mundo, via Saliva Diva, a Centelha acaba de se iluminar.",
  },
  {
    diva: 3,
    type: "album",
    name: "PALMIERS",
    artist: "PALMIERS",
    year: 2020,
    price: 10,
    priceDigital: 6,
    cover: process.env.PUBLIC_URL + "/assets/cover_palmiers.png",
    banner: process.env.PUBLIC_URL + "/assets/banner_palmiers.jpg",
    instagram: "https://www.instagram.com/_palmiers_/",
    facebook: "https://www.facebook.com/palmiersofficial/",
    email: "palmiers.music@gmail.com",
    youtube: "https://www.youtube.com/watch?v=_sVqbNS43dY",
    spotify:
      "https://open.spotify.com/artist/0RKjfv8dDi5eYoXfbTFPxw?si=SIOm3jE8Rm2arWT-LMmFug",
    bandcampLink: "https://salivadiva.bandcamp.com/album/palmiers",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=3415531701/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    bandcampMerch: "https://f4.bcbits.com/img/0020553863_10.jpg",
    review:
      "Chegámos a Pequim. As luzes do aeroporto desaparecem no horizonte, entre os cardumes de casas que parecem ninguém, os postes da luz fardados de semente de outros destinos e os diferentes sinais de trânsito, que com outra cara significam o mesmo que os nossos. Uma hospedeira corre tresloucada pelo apeadeiro e grita pelo nosso nome, o que nos obriga a virar a cara e a arrastar as malas enquanto ela nos transporta pelo braço até um voo incógnito. Já a bordo, descobrimos que o destino fica algures na América do Sul. A repousar nos assentos designados a viajantes espontâneos estão folhetos de uma banda - PALMIERS. Descem uns auscultadores de um compartimento acima das nossas cabeças, colocamo-los consoante as instruções, e somos transportados de continente em continente por uma música feita à medida da topografia que as nuvens ocultam. Fenómenos atmosféricos aparte, a música acompanha-nos até lugares exóticos que não estão presentes nos globos iluminados dos escritórios dos anos 70 e 80, como se um surfista de Honolulu se tivesse infiltrado numa banda de roqueiros com sincronia alemã e sangue latino. Ao aterrar, fica a memória de uma viagem que se deseja repetir, de espontaneidade e irreverência, preenchida por um exotismo com sabor a casa e uma energia de paisagem remota aqui, ao sabor dos dedos. Embora este álbum homónimo marque a estreia em disco dos PALMIERS, este quarteto formado no Porto já tem experiência ao vivo em palcos relevantes como Casa da Música, ZigurFest, Museum Festum, Festival A Porta, entre outros. O rock é a base de onde Ricardo Prado (guitarra), Tito Sousa (bateria), Gabriel Costa (teclados e percussões) e José Silva (baixo) partem em exploração de universos como o krautrock, o tropicalismo, o progressivo e o dreampop.",
  },
  {
    diva: 2,
    type: "album",
    name: "Bazar Esotérico",
    artist: "Conferência Inferno",
    year: 2020,
    cover: process.env.PUBLIC_URL + "/assets/cover_bazar_esoterico.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_conferencia_inferno.jpg",
    instagram: "https://www.instagram.com/conferenciainferno/?hl=en",
    facebook: "https://www.facebook.com/conf666/",
    email: "coletivofarra@gmail.com",
    youtube: "https://www.youtube.com/watch?v=QPRlyg3dh5k",
    spotify:
      "https://open.spotify.com/artist/53DQ3h5ag3n5FwEBbyv90z?si=N_QGrDGOS_CNBLElIHLfWA",
    bandcampLink: "https://salivadiva.bandcamp.com/album/bazar-esot-rico",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=793545451/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    bandcampMerch: "https://f4.bcbits.com/img/0018643656_36.jpg",
    review:
      "A primeira Conferência Inferno chegou para nos mostrar as conclusões das experiências controversas feitas clandestinamente por Raul Mendiratta e Francisco Lima, entre paredes de carácter duvidoso em Aveiro, Porto e Vila Real. Pop náutico e militarizado levou uma injecção de pica descontrolada e o kraut um supositório de tensão. Pastilhas coloridas de surrealismo foram dissolvidas na água da dark wave mais escura e o punk enfiado num colete de forças com fecho electrónico. Trazem-nos os resultados num pó de retratos nocturnos de uma cidade imberbe na arte de ser urgente. Uma dança com as contradições numa prova de vinhos em copos de plástico reutilizáveis. Um messianismo intermitente, sussurrado do topo de um prédio à venda. É viciante à brava e o desmame é complicado.",
  },
  {
    diva: 1,
    type: "album",
    name: "Doppler",
    artist: "O Manipulador",
    year: 2020,
    instagram: "https://www.instagram.com/o_manipulador/",
    facebook: "https://pt-pt.facebook.com/omanipulador/",
    email: "manuelmolarinho@gmail.com",
    youtube: "https://www.youtube.com/c/OManipulador",
    spotify:
      "https://open.spotify.com/artist/71s0ArNla54ra4Zh9irAZA?si=lxLQ_OOQTiCBNARxI53H3w",
    cover: process.env.PUBLIC_URL + "/assets/cover_doppler.jpg",
    banner: process.env.PUBLIC_URL + "/assets/banner_o_manipulador.jpg",
    bandcampLink: "https://salivadiva.bandcamp.com/album/doppler",
    bandcampSrc:
      "https://bandcamp.com/EmbeddedPlayer/album=1434638440/size=small/bgcol=ffffff/linkcol=7249b1/artwork=none/transparent=true/",
    bandcampMerch: "https://f4.bcbits.com/img/0018275574_10.jpg",
    review:
      "Seja nas suas estruturas internas como no próprio alinhamento, as músicas articulam-se com um refinamento que só existe quando as primeiras são criadas com a mesma medida de paixão e experiência. E o álbum corre e não se sente peça fora do sítio, os loops existem e, elegantemente, não soam a loops — soam a banda completa —, há espaço para a tensão (Bloody Tongue, Downtown ou Ground People), para os lamentos talvez dolorosos, talvez estoicos (Coward e Baby I Don’t Feel So Well), para a sedução, mais ou menos excitada (Voices in Your Head ou The Sheep) e para a frescura (If You Ruled the World e, especialmente, It’s You) — tudo isto equilibrado, por vezes dançável, por vezes depressivo, por vezes feliz, por vezes sóbrio, outras tantas, não, por vezes a caminhar pela cidade enquanto luta por evitar um cigarro à socapa depois de anunciado ao mundo ter desistido e, de vez em quando, sentado num banco sem saber para onde ir. E sempre humano, profundamente humano.",
  },
];

export default releases;
